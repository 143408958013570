import {
    React,
    useState,
} from "react";
import {
    Box,
    Flex,
    Heading,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import { differenceInCalendarDays } from 'date-fns';
import { FaCircle } from "react-icons/fa";
import { AddToCalendarButton } from 'add-to-calendar-button-react';


import dayjs from "dayjs";
import api from "../../utils/api";
import { useQuery } from 'react-query'

import Calendar from 'react-calendar';

import Header from '../../components/Header'
import ZonePicker from "../../components/ZonePicker";

import '../../theme/calendar.css';
import GameCard from "../../components/GameCard";

const VenueCalendarPage = () => {
    const { venueId } = useParams();
    const navigate = useNavigate();

    const [value, setValue] = useState(null);

    const venueQuery = useQuery(['venue', venueId], () => api('venues/' + venueId));
    const games = venueQuery.data?.games;

    const isSameDay = (a, b) => dayjs(a).format('YYYY-MM-DD') === dayjs(b).format('YYYY-MM-DD');

    const tileContent = ({ date: tmp, view }) => {
        let numGames = games?.filter((g) => isSameDay(g.date, tmp)).length;
        if (numGames === 1) return (<span><Icon as={FaCircle} fontSize={8} color="orange" /></span>);
        else if (numGames > 1) return (<span><Icon as={FaCircle} fontSize={8} color="orange" /><Icon as={FaCircle} fontSize={8} color="orange" /></span>);
        else return null;
    };

    const showGame = (game) => {
        navigate("/games/" + game.id);
    };

    const renderGames = (tmp) => {
        const todayGames = games?.filter((g) => isSameDay(g.date, tmp));

        return <VStack mt={8}>
            <Heading size="md"> {dayjs(tmp).format('dddd, MMMM D, YYYY')} </Heading>
            {todayGames.length ? todayGames.map((game) => <GameCard game={game} callback={showGame} />) : null}
            {todayGames.length === 0 ? <Text>Nothing scheduled for this day.</Text> : null}
        </VStack>
    };


    return <Box>
        <Header back={"/venues/" + venueQuery?.data?.id} backLabelo="Back to Venue" />
        <Flex direction={'column'} w={'100%'} alignItems={'center'}>
            <Calendar
                calendarType="gregory"
                tileContent={tileContent}
                onChange={(date) => setValue(date)}
                onClickMonth={() => setValue(null)}
                onActiveStartDateChange={() => setValue(null)}
                onClickYear={() => setValue(null)}
                value={value} />
            <AddToCalendarButton
                subscribe={true}
                name="Sync to My Calendar"
                hideCheckmark={true}
                buttonStyle="flat"
                size={3}
                lightMode="dark"
                label="Sync to My Calendar"
                options={['Apple', 'Google', 'Outlook.com', 'Microsoft365',]}

                icsFile={`https://app.theadventureenxus.com/api/venues/${venueId}/ical`}
            />

            {value && renderGames(value)}
        </Flex>
        <ZonePicker />
    </Box>
}



export default VenueCalendarPage;
