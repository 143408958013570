import {
  React,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  useDisclosure,
  Box,
  Button,
  Card,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  Text,
  Link,
  VStack,
  Switch,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import { differenceInCalendarDays } from 'date-fns';
import { FaCircle } from "react-icons/fa";
import ZonePicker from "../../components/ZonePicker";
import { AddToCalendarButton } from 'add-to-calendar-button-react';


import dayjs from "dayjs";
import api from "../../utils/api";
import useUserState from "../../stores/user";
import { useQuery } from 'react-query'

import Calendar from 'react-calendar';

import Header from '../../components/Header'

import '../../theme/calendar.css';
import GameCard from "../../components/GameCard";

const MyCalendarPage = ({ mode }) => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);

  const { data: games, isLoading: gamesLoading } = useQuery(
    ['games', 'allmine'],
    () => api('/games/allmine', false, 'QUERY')
  );

  const myGames = games || [];

  const isSameDay = (a, b) => dayjs(a).format('YYYY-MM-DD') === dayjs(b).format('YYYY-MM-DD');


  const tileContent = ({ date, view }) => {
    const numGames = myGames.filter((g) => isSameDay(g.date, date)).length;
    if (numGames == 1) return (<span><Icon as={FaCircle} fontSize={8} color="orange" /></span>);
    else if (numGames > 1) return (<span><Icon as={FaCircle} fontSize={8} color="orange" /><Icon as={FaCircle} fontSize={8} color="orange" /></span>);
    else return null;
  };

  const showGame = (game) => {
    navigate("/games/" + game.id);
  };

  const renderGames = (date) => {
    const games = myGames.filter((g) => isSameDay(g.date, date)).sort((a, b) => dayjs(a.date+' '+a.startTime).diff(dayjs(b.date+' '+b.startTime)));

    return <VStack mt={8}>
      <Heading size="md"> {dayjs(date).format('dddd, MMMM D, YYYY')} </Heading>
      {games.length ? games.map((game) => <GameCard game={game} callback={showGame} />) : <Text>No games scheduled</Text>}
    </VStack>
  };


  return <Box>
    <Header back="/account" backLabel="Back to Account" />
    <Flex direction={'column'} w={'100%'} alignItems={'center'}>
      <Calendar
        calendarType="gregory"
        tileContent={tileContent}
        onChange={(date) => setValue(date)}
        onClickMonth={() => setValue(null)}
        onActiveStartDateChange={() => setValue(null)}
        onClickYear={() => setValue(null)}
        value={value} />

      <AddToCalendarButton
        subscribe={true}
        name="Sync to My Calendar"
        hideCheckmark={true}
        buttonStyle="flat"
        size={3}
        lightMode="dark"
        label="Sync to My Calendar"
        options={['Apple', 'Google', 'Outlook.com', 'Microsoft365']}

        icsFile={`https://app.theadventureenxus.com/api/users/${user.id}/ical`}
      />

      {value && renderGames(value)}
    </Flex>
    <ZonePicker />
  </Box>
}



export default MyCalendarPage;
