import React, { useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Text,
  Link,
  VStack,
  Code,
  Grid
} from '@chakra-ui/react';

import { theme } from "./theme";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation
} from "react-router-dom";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'




import RegisterPage from "./pages/RegisterPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import GamesPage from "./pages/GamesPage";
import GamePage from "./pages/GamePage";
import GamePlayersPage from "./pages/GamePlayersPage";
import FavoritesPage from "./pages/FavoritesPage";
import VenuesPage from "./pages/VenuesPage";

import ConferencesPage from "./pages/ConferencesPage";
import ConferenceAttendeesPage from "./pages/ConferenceAttendeesPage";
import ConferencePage from "./pages/ConferencePage";

import VenueCalendarPage from "./pages/venue/VenueCalendarPage";
import VenueStaffPage from "./pages/venue/VenueStaffPage";
import VenuePage from "./pages/venue/VenuePage";
import CreateVenuePage from "./pages/venue/CreateVenuePage";

import CreateGamePage from "./pages/CreateGamePage";
import UserProfilePage from "./pages/UserProfilePage";
import TermsPage from "./pages/TermsPage";

import MyCalendarPage from "./pages/account/MyCalendarPage";
import SetupAccountPage from "./pages/account/SetupAccountPage";
import AccountPage from "./pages/account/AccountPage";
import ChangePasswordPage from "./pages/account/ChangePasswordPage";
import HelpPage from "./pages/account/HelpPage";
import InvitePage from "./pages/account/InvitePage";
import NotificationsPage from "./pages/account/NotificationsPage";

import useUserState from "./stores/user";
import api from "./utils/api";


function App() {
  const queryClient = new QueryClient();
  const { user, logout, setLoggedOutMsg } = useUserState((state) => {
    return {
      user: state.user,
      logout: state.logout,
      setLoggedOutMsg: state.setLoggedOutMsg,
    }
  });
  //console.log(isLoading, isAuthenticated, error, user);
  const validateuser = async () => {
    //console.log('validateuser');
    if (user) {
      let me = await api('user/me', null, 'GET');
      if (!me || me.error) {
        logout();
        setLoggedOutMsg('Session expired, please login again.');
      }
    }
    return null;
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: user ? <GamesPage /> : <LandingPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/forgot',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/terms',
      element: <TermsPage />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/signup',
      element: <LoginPage mode="signup" />,
    },
    {
      path: '/account/setup',
      element: user ? <SetupAccountPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/account/invite',
      element: user ? <InvitePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/account/password',
      element: user ? <ChangePasswordPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/help',
      element: <HelpPage />,
    },
    {
      path: '/account',
      element: user ? <AccountPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/account/notifications',
      element: user ? <NotificationsPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/account/verification/callback',
      element: user ? <AccountPage checkVerification={true} /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/favorites',
      element: user ? <FavoritesPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/mine',
      element: user ? <VenuesPage mine={true} /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/create',
      element: user ? <CreateVenuePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/:venueId/calendar',
      element: user ? <VenueCalendarPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/:venueId/edit',
      element: user ? <CreateVenuePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/:venueId/staff',
      element: user ? <VenueStaffPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues/:venueId',
      element: user ? <VenuePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/venues',
      element: user ? <VenuesPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/conferences',
      element: user ? <ConferencesPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/conferences/:conferenceId',
      element: user ? <ConferencePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/conferences/:conferenceId/attendees',
      element: user ? <ConferenceAttendeesPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games',
      element: user ? <GamesPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/create',
      element: user ? <CreateGamePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/create/for/:venueId',
      element: user ? <CreateGamePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/create/forcon/:conferenceId',
      element: user ? <CreateGamePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/mine',
      element: user ? <GamesPage mode="mine" /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/calendar',
      element: user ? <MyCalendarPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/:gameId',
      element: <GamePage />,
      loader: validateuser,
    },
    {
      path: '/games/:gameId/players',
      element: user ? <GamePlayersPage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/:gameId/edit',
      element: user ? <CreateGamePage /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/games/:gameId/dupe',
      element: user ? <CreateGamePage mode="dupe" /> : <LoginPage />,
      loader: validateuser,
    },
    {
      path: '/users/:userId',
      element: user ? <UserProfilePage /> : <LoginPage />,
      loader: validateuser,
    },
  ]);
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

const WrappedApp = () => {
  /*
    let location = useLocation();
  
    React.useEffect(() => {
      // Google Analytics
      //window.ga('send', 'pageview');
    }, [location]);
  */

  return <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    useRefreshTokensFallback={true}
    authorizationParams={{
      redirect_uri: window.location.origin + '/login',
    }}
  >
    <App />
  </Auth0Provider>

}

export default WrappedApp;
