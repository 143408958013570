import React from 'react';
import {
  useDisclosure,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Image,
  VStack,
  Input,
  Icon,
  Text,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import api from '../utils/api';
import { useQuery } from 'react-query';
import useUserState from '../stores/user';
import dayjs from 'dayjs';
import { CSVLink, CSVDownload } from 'react-csv';



const ConferenceAttendeesPage = () => {
  const { conferenceId } = useParams();
  const user = useUserState(state => state.user);
  const navigate = useNavigate();

  const conferenceQuery = useQuery(['conference', conferenceId], () =>
    api('/conferences/' + conferenceId)
  );
  const con = conferenceQuery.data;

  const attendeesQuery = useQuery(['conferenceattendees', conferenceId], () =>
    api('/conferences/' + conferenceId + '/attendees')
  );
  const attendees = attendeesQuery.data;

  const checkIn = async (a, val) => {
    await api('/conferences/' + conferenceId + '/attendees/' + a.registration?.id + '/checkin', {val}, 'POST');
    attendeesQuery.refetch();
  };

  const csvData = attendees ? attendees?.map(a => {
    return [
      a.registration?.registrationDetails?.name || 'Not provided',
      a.registration?.registrationDetails?.phone || 'Not provided',
      a.user?.email,
      a.checkedIn ? 'Yes' : 'No',
    ];
  }) : [];

  const csvHeaders = [ "Name", "Phone", "Email", "Checked In" ];

  const csvFilename = (con ? con.name : '' ) + '-Attendees.csv';

  return (
    <>
      <Box>
        <Header />
        <HStack justify="space-between" mx={4}>
          <Link to={'/conferences/' + conferenceId}>
            &lt; Back to Conference
          </Link>
            <CSVLink data={csvData} headers={csvHeaders} filename={csvFilename} target="_blank"><Button>Download CSV</Button></CSVLink>;
        </HStack>
        <Flex direction={'column'} w={'100%'} alignItems={'center'}>
          <VStack align="start">
            {con && <Heading size="lg"> {con.name} Attendees </Heading>}
            {con && <Heading size="md">  {attendees?.length} Attendees, {attendees?.filter(a => a.checkedIn).length} Checked In </Heading>}
            {attendees?.map(a => (
              <Card mb={2}>
                <CardBody py={4} w="100%">
                  <HStack
                    key={a.registration.id}
                    align="center"
                    justify="space-between"
                  >
                    <VStack align="start" w="200px">
                      <Text fontSize="xs" color="muted">
                        Name
                      </Text>
                      <Text>
                        {a.registration?.registrationDetails?.name ||
                          'Not provided'}
                      </Text>
                    </VStack>
                    <VStack align="start" ml={10} w="120px">
                      <Text fontSize="xs" color="muted">
                        Phone
                      </Text>
                      <Text>
                        {a.registration?.registrationDetails?.phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') ||
                          'Not provided'}
                      </Text>
                    </VStack>
                    <VStack align="start" ml={10}>
                      <Text fontSize="xs" color="muted">
                        Registered
                      </Text>
                      <Text>
                        {dayjs(a.registration.createdAt).format('MMM D')}
                      </Text>
                    </VStack>
                    <VStack ml={10}>
                      {!a.registration.checkedIn ? (
                        <Button onClick={() => checkIn(a, true)}>
                          Check In
                        </Button>
                      ) : (
                        <>
                          <Text color="green">Checked In</Text>
                          <Link onClick={() => checkIn(a, false)}>
                            <Text fontSize="xs" color="Red">
                              Oops, uncheck in
                            </Text>
                          </Link>
                        </>
                      )}
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>
            ))}
          </VStack>
        </Flex>
      </Box>
    </>
  );
};

export default ConferenceAttendeesPage;
