import React, { useRef, useState } from 'react';
import {
  useDisclosure,

  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,

} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import api from '../utils/api';
import { useQuery } from 'react-query';
import { FaMap, FaMapPin, FaLink } from 'react-icons/fa';
import Linkify from 'react-linkify';
import { linkify } from '../utils/venues';
import dayjs from 'dayjs';

import useUserState from '../stores/user';

const ConferencesPage = props => {
  const conferencesQuery = useQuery('conferences', () => api('conferences'));
  const navigate = useNavigate();
  const user = useUserState(state => state.user);



  return (
    <Box>
      <Header />
      <Flex direction={'column'} w={'100%'} alignItems={'center'}>
        <Heading>Conferences</Heading>
        <Flex wrap="wrap" justifyContent="center">
          {conferencesQuery.data?.map(c => {
            let borderColor = null;
            let boxShadow = null;
            if (c.attending) {
              borderColor = 'green';
              boxShadow = '0 0 10px 2px ' + borderColor;
            }
            return (
              <Card
                key={c.id}
                m={4}
                width={["sm", "md"]}
                borderWidth={1}
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  boxShadow: boxShadow,
                  borderColor: borderColor,
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/conferences/' + c.id)}
              >
                <Box style={{ position: 'relative',}}>
                {c.banner && (
                  <Box style={{ position: 'relative',}}>
                    <Image
                      objectFit="contain"
                      src={c.banner}
                      alt={c.name + ' banner'}
                    />
                  </Box>
                )}
                {c.attending && (
                  <Text
                    fontSize="sm"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      marginLeft: '-3em',
                      width: '6em',
                      color: 'white',
                      zIndex: 1,
                      borderRadius: '0 0 15px 15px',
                      textAlign: 'center',
                      backgroundColor: borderColor,
                      boxShadow: boxShadow,
                    }}
                  >
                    Registered
                  </Text>
                )}
                </Box>
                <CardBody justify="space-between">
                  <Flex
                    direction={["column","row"]}
                    align="start"
                    justify="space-between"
                    mb={4}
                    style={{ marginTop: c.banner ? '-10px' : null }}
                  >
                    <Heading size="md">{c.name}</Heading>
                    <Heading size="sm">
                      {dayjs(c.start_date).format('M/D/YY')}
                      {c.start_date !== c.end_date &&
                        ' - ' + dayjs(c.end_date).format('M/D/YY')}
                    </Heading>
                  </Flex>
                  <VStack align="start">
                    {c.location && (
                      <Text color="muted">
                        <Icon as={FaMapPin} mr={2} color="white" />
                        {c.location}
                      </Text>
                    )}
                    {c.website && (
                      <Link
                        color="muted"
                        onClick={e => e.stopPropagation()}
                        target="_blank"
                        href={linkify(c.website)}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        <Text
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Icon as={FaLink} mr={2} color="white" />
                          {c.website?.replace(/https?:\/\//, '')}
                        </Text>
                      </Link>
                    )}

                  </VStack>
                  <Box mt={2} className="linkify">
                    <Text fontWeight="bold" color="white">
                      Description
                    </Text>
                    <Linkify>
                      <Text style={{ whiteSpace: 'pre-wrap' }} color="muted">
                        {c.description}
                      </Text>
                    </Linkify>
                  </Box>
                </CardBody>
              </Card>
            );
          })}
        </Flex>
      </Flex>
    </Box>
 
  );
};

export default ConferencesPage;
